
export default {
  name: 'MegasredaContentProductFeedCard',
  props: {
    product: {
      type: Object,
      default: null,
    },
    isHasComment: {
      type: Boolean,
      default: false,
    },
    topMessage: {
      type: String,
      default: null,
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      streamsVisibleCount: 3,
      isSetShow: false,
    }
  },
  computed: {
    isAuthor() {
      if (this.author?._id) {
        return this.$auth?.user?._id === this.author._id
      } else {
        return false
      }
    },
    thematicStreams() {
      if (this.product?.streams) {
        return this.product?.streams?.filter(
          (stream) => stream.type === 'THEMATIC'
        )
      } else {
        return []
      }
    },
    isAdmin() {
      return !!this.$auth?.user?.roles?.some((role) =>
        ['ADMIN', 'MODERATOR'].includes(role)
      )
    },
    streams() {
      return this.product?.streams.slice(0, this.streamsVisibleCount)
    },
    author() {
      const author = Array.isArray(this.product?.author)
        ? this.product?.author[0]
        : this.product?.author
      return author
    },
    imageRetinaUrl() {
      if (this.product.leadImg) {
        return this.product.leadImg
      }
      return null
    },
    preText() {
      if (this.product?.shortDescription) {
        return this.product.shortDescription
      } else if (this.product?.description) {
        const text = this.product.description
        if (text?.blocks?.length) {
          const preText = text.blocks.find(
            (block) => block.type === 'paragraph' || block.type === 'header'
          )
          return preText?.data?.text
        }
      }
      return null
    },
    price() {
      switch (this.product.price?.priceDisplay) {
        case 'FREE':
          return 'Бесплатно'
        case 'AGREEMENT':
          return 'По договорённости'
        case 'EXACT':
          return `${new Intl.NumberFormat('ru-Ru').format(
            this.product.price?.exactPrice
          )}\u00A0руб.`
        case 'RANGE':
          return this.product.price?.rangeEndPrice
            ? `От ${new Intl.NumberFormat('ru-Ru').format(
                this.product.price?.rangeStartPrice
              )}\u00A0руб. до ${new Intl.NumberFormat('ru-Ru').format(
                this.product.price?.rangeEndPrice
              )}\u00A0руб.`
            : `От ${new Intl.NumberFormat('ru-Ru').format(
                this.product.price?.rangeStartPrice
              )}\u00A0руб.`
        default:
          return null
      }
    },
    oldPrice() {
      return this.product.price?.oldPrice
    },
    provisionType() {
      return this.product.provisionType === 'ONLINE' ? `Онлайн` : `Офлайн`
    },

    address() {
      return this.product?.address?.city?.name
        ? `\u00A0·\u00A0${this.product?.address?.city?.name}`
        : null
    },
  },
  methods: {
    inView({ isInView }) {
      if (isInView && !this.isSetShow && !this.draft) {
        this.setShow()
      }
    },
    setShow() {
      this.$axios.$post('/activity/show/advertisement', {
        username: this.author.username,
        advertisementId: this.product._id,
      })
      this.isSetShow = true
    },
    closeMenu() {
      this.isShowStreamsPopUp = false
    },
  },
}
