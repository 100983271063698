
export default {
  name: 'MegasredaContentProductHeaderInfo',
  props: {
    product: {
      type: [Object, Array],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    telegram: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    author() {
      const author = Array.isArray(this.product?.author)
        ? this.product?.author[0]
        : this.product?.author
      return author
    },
    auditorium() {
      if (this.author?.statistics?.auditorium < 100) {
        return 'менее 100'
      } else {
        return this.author?.statistics?.auditorium
      }
    },
    authorName() {
      return this.author?.profile?.firstName && this.author?.profile?.lastName
        ? `${this.author?.profile.firstName} ${this.author?.profile.lastName}`
        : this.author?.username
    },
  },
}
