
export default {
  name: 'UiFeedCard',
  props: {
    isHasComment: {
      type: Boolean,
      default: false,
    },
    topMessage: {
      type: String,
      default: null,
    },
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer
    },
  },
}
